<template>
  <div class="dd-home-zy2">
    <h3>专有钉钉—数字化协同平台</h3>
    <p>强协同、提效能、助决策、保安全，通过云智能和移动互联网技术，助力大型政企组织数字化转型</p>
    <ul class="ul_1">
      <li v-for="(item, index) in list" :key="index" class="iconfont icon-gouxuan">{{ item }}</li>
    </ul>
    <ul class="ul_2">
      <li>
        <img src="/static/img/dingtalk-zy2-icon1.png" />
        <h2>互通能力</h2>
        <p>支持跨域跨组织互联<br />
          零时差单聊群聊互通</p>
      </li>
      <li>
        <img src="/static/img/dingtalk-zy2-icon2.png" />
        <h2>组织管理</h2>
        <p>百万人员轻松管理<br />
          分级权限精准管控</p>
      </li>
      <li>
        <img src="/static/img/dingtalk-zy2-icon3.png" />
        <h2>部署方式</h2>
        <p>灵活支持专网部署<br />
          和本地部署</p>
      </li>
      <li>
        <img src="/static/img/dingtalk-zy2-icon4.png" />
        <h2>开放集成</h2>
        <p>更开放设计与集成能力<br />
          高效接入业务应用</p>
      </li>
    </ul>
    <div class="btn-list">
      <a :href="onLine" target="_blank">立即咨询</a>
      <button @click="$router.push('/cloud/dingtalk/zy2')">了解详情</button>
    </div>
  </div>
</template>
<script>
import { onLine } from "@/data.ts";
export default {
  data() {
    return {
      onLine,
      list: [
        '支持专有化部署，数据落本地，更安全',
        '客户可对该产品持牌运营',
        '结构化编排，协助构建本地应用中心',
        '提供移动应用全面安全保障体系',
      ]
    }
  },
}
</script>
<style lang="less" scoped>
.dd-home-zy2 {
  padding: 50px 15px;
  color: #383838;

  >h3 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }

  >p {
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
    margin-bottom: 40px;
  }

  >ul {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -10px;

    >li {
      width: 50%;
      margin: 0;
      padding: 10px;
    }

    &.ul_1 {
      >li {
        position: relative;
        padding-left: 30px;
        font-size: 14px;

        &::before {
          position: absolute;
          left: 10px;
          line-height: 1.5;
          color: #E80B10;
        }
      }
    }

    &.ul_2 {
      >li {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
        text-align: center;
        padding: 20px 10px;

        >img {
          width: 60px;
          height: 60px;
        }

        >h2 {
          font-weight: bold;
          font-size: 15px;
          line-height: 2;
        }

        >p {
          font-size: 14px;
        }
      }
    }
  }



  .btn-list {
    display: flex;
    margin-top: 40px;

    >button,>a {
      text-align: center;
      display: inline-block;
      width: 50%;
      margin: 0 10px;
      border: 0 none;
      background: none;
      appearance: none;
      border-radius: 3px;
      line-height: 42px;
      font-size: 16px;
      font-weight: bold;

      &:first-child {
        background-color: #E80B10;
        color: #fff;
      }

      &:last-child {
        border: 1px solid #E80B10;
        color: #E80B10;
      }
    }
  }
}
</style>